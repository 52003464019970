'use client';
import { cn } from '@av/utils';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { ArrowRight } from 'lucide-react';
import React from 'react';
import Link from 'next/link';

const buttonVariants = cva(
  'relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-base font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-fg hover:bg-primary/90',
        secondary: 'bg-secondary text-secondary-fg hover:bg-secondary/90',
        light: 'bg-primary/10 text-main ring-1 ring-primary/60',
        destructive: 'bg-danger text-danger-fg hover:bg-danger/90',
        outline: 'border bg-transparent hover:bg-gray-100 hover:text-foreground',
        shadow: 'shadow bg-transparent hover:bg-neutral-100 hover:text-foreground',
        ghost: 'hover:bg-neutral-100 hover:text-foreground/90',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      rounded: {
        default: 'rounded-md',
        sm: 'rounded-sm',
        md: 'rounded-md',
        lg: 'rounded-xl',
        full: 'rounded-full',
        none: 'rounded-none',
      },
      size: {
        default: 'h-[50px] px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-16 rounded-md px-8',
        icon: 'h-10 w-10 rounded-full [&:hover>svg]:scale-[1.05] transition-all [&:hover>svg]:stroke-white hover:bg-primary [&>svg]:ease-[cubic-bezier(1,0.29,0.42,1.24)] [&>svg]:duration-300 [&>svg]:transition-transform',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      rounded: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  href?: string;
  withArrow?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, rounded, asChild = false, withArrow, children, href, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    if (href) {
      return (
        <Link
          className={cn(buttonVariants({ variant, rounded, size, className }))}
          href={href}
          {...props}
        >
          {children}

          {withArrow ? <ArrowRight className="size-5 ml-2" /> : null}
        </Link>
      );
    }

    return (
      <Comp
        className={cn(buttonVariants({ variant, rounded, size, className }))}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
