'use client';

import React, { useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '../popover';
import { Button, ButtonProps } from '..';
import { cn, formatDate, formatUTCDate } from '@av/utils';
import { CalendarIcon } from 'lucide-react';
import { Calendar, CustomBlockedDate } from '../calendar';
import { Matcher, SelectSingleEventHandler } from 'react-day-picker';

const DatePicker = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    date?: Date;
    onSelect: SelectSingleEventHandler;
    isDisabled?: Matcher;
    placeholder?: string;
    appendToDate?: string;
    customBlockedDates?: CustomBlockedDate[];
  }
>(
  (
    {
      className,
      date,
      onSelect,
      isDisabled,
      placeholder,
      appendToDate,
      customBlockedDates,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect: SelectSingleEventHandler = (selectedDate, ...rest) => {
      onSelect(formatUTCDate(selectedDate as Date) as Date, ...rest);
      setIsOpen(false);
    };

    return (
      <Popover onOpenChange={setIsOpen} open={isOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            {...props}
            className={cn(
              'w-full bg-white pl-3 font-normal text-left px-5 h-[3.5rem] text-base justify-start',
              date ? 'text-black' : 'text-muted-fg',
              className
            )}
            onClick={() => setIsOpen((prev) => !prev)} // Toggle the popover when button is clicked
            ref={ref}
          >
            {date ? (
              <div className="space-x-2 max-w-[calc(100%-1rem)] truncate">
                <span>{formatDate(date)}</span>
                {!!appendToDate && (
                  <span className="text-muted-fg text-sm ml-2">{appendToDate}</span>
                )}
              </div>
            ) : (
              <span className="text-muted-fg">{placeholder || 'Kies een datum'}</span>
            )}
            <CalendarIcon className="absolute right-5 top-1/2 -translate-y-1/2 size-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-auto p-0">
          <Calendar
            customBlockedDates={customBlockedDates}
            disabled={isDisabled}
            initialFocus
            mode="single"
            onSelect={handleSelect} // Use the custom handler
            selected={date}
          />
        </PopoverContent>
      </Popover>
    );
  }
);
DatePicker.displayName = 'DatePicker';

export { DatePicker };
